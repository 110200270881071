<template>
    <section class="popularshops-block">
        <div class="content-head">
            <h3 class="heading">
                {{ title }}
            </h3>
            <NuxtLink no-prefetch v-if="path" :to="path" rel="follow">{{
                $lang.components.sidebarStores.see_more
            }}</NuxtLink>
        </div>

        <ul v-if="info.length > 0" class="popularshops-block__popular-shops">
            <li v-for="(item, index) in info" :key="index">
                <NuxtLink
                    no-prefetch
                    :to="`${path}/${item.slug}`"
                    rel="follow"
                    :title="'Cupones ' + item.name"
                >
                    <img
                        v-if="item.image"
                        :src="item.image.url"
                        :alt="item.name"
                        :onerror="`this.onerror=null;this.src='${
                            defaultImage[path === `/${$lang.routes.brands}` ? 'store' : 'category']
                        }'`"
                    />
                </NuxtLink>
            </li>
        </ul>
        <p v-else class="no-similar">{{ $lang.components.sidebarStores.popular_stores }}</p>
    </section>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { Models } from '~/types/models'
import { useVariantsDictionary } from '~/composables/VariantsDictionary'

export default defineComponent({
    name: 'SidebarStores',
    props: {
        path: {
            type: String,
            required: true,
        },
        info: {
            type: Array as PropType<Models.Store[]>,
            required: true,
        },
        title: {
            type: String,
        },
        model: {
            type: String as PropType<'secondary'>,
            default: 'primary',
        },
        border: {
            type: Boolean,
            default: true,
        },
        iconColor: {
            type: String as PropType<'primary'>,
            default: 'primary',
        },
        iconName: {
            type: String as PropType<'shop'>,
            default: 'shop',
        },
    },
    data() {
        const { defaultImages } = useVariantsDictionary()
        return {
            defaultImage: defaultImages,
        }
    },
    computed: {
        icon(): object {
            // @ts-ignore
            return this.$assets[this.iconColor][this.iconName]
        },
    },
})
</script>

<style lang="postcss">
.popularshops-block {
    @apply rounded-2xl border border-opacity-5 bg-white p-3;

    .content-head {
        @apply mb-2 flex justify-between border-b border-white;
        .heading {
            @apply text-lg font-thin;
        }
        a {
            @apply self-center text-sm font-medium text-site-primary hover:underline;
        }
    }

    &__popular-shops {
        @apply grid grid-cols-3 place-items-center gap-y-2.5 xs:grid-cols-4 sm:grid-cols-6 md:grid-cols-7 lg:grid-cols-3;
        li {
            @apply overflow-hidden rounded-lg border bg-white hover:border-gray-300;
            a {
                @apply block h-20 w-20 lg:h-12 lg:w-12 xl:h-20 xl:w-20;
                img {
                    @apply h-full w-full object-contain p-2;
                }
            }
        }
    }
    .no-similar {
        @apply py-4 text-center text-site-gray;
    }
}
</style>
